import React from 'react';
import { View, FlatList } from 'react-native';
import { ListItem, Icon } from 'react-native-elements'


export default function ListLocations({route, navigation}) {
	const { locations, categoriesHash } = route.params;
	const keyExtractor = (item) => item.id;

	const renderItem = ({item}) => { 
                            let location = item;
                            location.category = categoriesHash[location.categoryId];

                            return <ListItem key={item.id}
							  containerStyle={{padding: 15}} 
							  onPress={ () => {
                                  navigation.navigate('locationDetails', {location, title: location.title} )
                               } }
							  bottomDivider
						     >
                                 <Icon
                                    name='map-marker-radius'
                                    type='material-community'
                                    color={location.category.color}
                                    containerStyle={ { padding: 3 } }
                                    />
                                 <ListItem.Content>
                                     <ListItem.Title>{item.title}</ListItem.Title>
                                     <ListItem.Subtitle>{item.address}</ListItem.Subtitle>
                                 </ListItem.Content>
                                 <ListItem.Chevron />
                             </ListItem>
    }

    return (
        <View style={{flex: 1}}>
			<FlatList 
			    data={locations} 
			    removeClippedSubviews={true}
			    initialNumToRender={6}
			    maxToRenderPerBatch={6}
			    keyExtractor={keyExtractor}
			    renderItem={renderItem}
			    windowSize={20}	
			/>				
        </View>
    )
}