import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { responsiveHeight, responsiveFontSize } from "react-native-responsive-dimensions";
import Menu from './../screens/menu/Menu';
import HorizontalMenu from './../screens/menu/HorizontalMenu';
import ContactForm from './../screens/form/ContactForm';
import CustomContent from './../screens/CustomContent';
import WebView from './../screens/WebView';
import PhotoGallery from './../screens/photoGallery/PhotoGallery';
import GallerySwipper from './../screens/photoGallery/GallerySwipper';
import SingleMarketMap from './../screens/map/SingleMarketMap';
import ManyLocationsMap from './../screens/map/ManyLocationsMap';
import ListLocations from './../screens/map/ListLocations';
import LocationDetails from './../screens/map/LocationDetails';
import FullScreenMap from './../screens/map/FullScreenMap';
import YoutubeVideoList from './../screens/YoutubeVideoList';
import Poll from './../screens/Poll';
import EventList from './../screens/events/EventList';
import EventDetail from './../screens/events/EventDetail';



const Stack = createStackNavigator();

export default function Navigation() {

    const titleMenu = ({route}) => {
        const title = route.params ? route.params.title : "Servicios al Contribuyente";
        return { title };
    }
    
    return (
        <NavigationContainer>
            <Stack.Navigator
                screenOptions={ {
                    headerTintColor: '#fff',
                    headerStyle: { 
                        backgroundColor: '#a39e9d',
                        shadowColor: 'transparent',
                        height: responsiveHeight(8),
                    },
                    headerTitleStyle: {
                        fontSize: responsiveFontSize(2.7)
                    }
                } }
            >
                <Stack.Screen name="menu" component={Menu} options={ titleMenu }  />
                <Stack.Screen name="horizontalMenu" component={HorizontalMenu} options={ ({route}) => ({ title: route.params.title }) } />
                <Stack.Screen name="contactForm" component={ContactForm} options={ ({route}) => ({ title: route.params.title }) } />
                <Stack.Screen name="customContent" component={CustomContent} options={ ({route}) => ({ title: route.params.title }) } />
                <Stack.Screen name="webEmbeded" component={WebView} options={ ({route}) => ({ title: route.params.title }) } />
                <Stack.Screen name="photoGallery" component={PhotoGallery} options={ ({route}) => ({ title: route.params.title }) } />
                <Stack.Screen name="gallerySwipper" component={GallerySwipper} options={ ({route}) => ({ title: route.params.title }) } />
                <Stack.Screen name="singleMarketMap" component={SingleMarketMap} options={ ({route}) => ({ title: route.params.title }) } />
                <Stack.Screen name="manyLocationsMap" component={ManyLocationsMap} options={ ({route}) => ({ title: route.params.title }) } />
                <Stack.Screen name="listLocations" component={ListLocations} options={ ({route}) => ({ title: route.params.title }) } />
                <Stack.Screen name="locationDetails" component={LocationDetails} options={ ({route}) => ({ title: route.params.title }) } />
                <Stack.Screen name="fullScreenMap" component={FullScreenMap} options={ ({route}) => ({ title: route.params.title }) } />
                <Stack.Screen name="youtubeVideoList" component={YoutubeVideoList} options={ ({route}) => ({ title: route.params.title }) } />
                <Stack.Screen name="poll" component={Poll} options={ ({route}) => ({ title: route.params.title }) } />
                <Stack.Screen name="events" component={EventList} options={ ({route}) => ({ title: route.params.title }) } />
                <Stack.Screen name="eventDetail" component={EventDetail} options={ ({route}) => ({ title: route.params.item.name }) } />

            </Stack.Navigator>
        </NavigationContainer>
    )
}