import React, { useLayoutEffect } from 'react';
import { StyleSheet, Text, ScrollView, View, Dimensions, Image, TouchableOpacity, Share } from 'react-native';
import { Icon, Button, Divider } from 'react-native-elements';
import * as Linking from 'expo-linking';
import { responsiveFontSize } from 'react-native-responsive-dimensions';


export default function LocationDetails({ navigation, route }) {
  const { title, latitude, longitude, address, description=undefined, category } = route.params.location;
  const apiKey = "AIzaSyDSLgUSBKRyFoFJ7yxFxxQyIoRh7TOPjiw";
  const zoom = 15;
  const urlStaticMapImage = `https://maps.googleapis.com/maps/api/staticmap?zoom=${zoom}&size=620x400&maptype=roadmap&markers=color:red%7C${latitude},${longitude}&key=${apiKey}`;
  const urlGoogleMapsToShare = `https://maps.google.com/?q=${latitude}%2C${longitude}`;

  useLayoutEffect(()=>{
    navigation.setOptions({
      headerRight: () => <Icon size={responsiveFontSize(2.5)} containerStyle={ {marginRight: 10} } onPress={shareLocation} name='share' type='materialicons' color='white'/>
    })
  }, [navigation]);

  const linkingGoogleMapsApp = () => {
    //window.open(`https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}&dir_action=navigate`, '_blank');
    Linking.openURL(`https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}&dir_action=navigate`);
  };

  const shareLocation = async () => {
    try {
      const message = `${address} (${urlGoogleMapsToShare})`;
      await Share.share({message});
    } catch (e) {
      alert('opción no disponible en su dispositivo');
    }
	}

	return (
    <ScrollView style={styles.container}>
      <Text style={ {fontSize: 25, fontWeight: 'bold', marginTop: 8} }> { title }  </Text>
      <View style={ {padding: 10, display: 'flex', flexDirection:'row'} }>
	      <Icon
			  name='map-marker-radius'
			  type='material-community'
        color={category.color}
        containerStyle={{marginRight: 5, marginTop: 2}}
			/>
	      <Text style={ { fontSize: 20} }>{ category.name  }</Text>
      </View>
      <Divider style={{ backgroundColor: 'black', marginTop: 1, height: 2 }} />

      <Text style={styles.direction}>{address}</Text>
      
      <TouchableOpacity onPress={ () => navigation.navigate('fullScreenMap', { latitude, longitude, title, description, address } ) } >
        <Image style={styles.mapStyle} source={ {uri: urlStaticMapImage} } />
      </TouchableOpacity>
      
      <Button title='Llévame ahí' 
        buttonStyle={ {backgroundColor: '#a39e9d', marginLeft: 5, marginRight: 5 } }
        titleStyle={ {color: 'white'} }
        onPress={ linkingGoogleMapsApp }
        />

      { description != undefined && <Text style={styles.description}>{description}</Text> }
      
      </ScrollView>
    );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  direction: {
    padding: 8,
  },
  description: {
    margin: 8,
    textAlign: 'left',
  },
  mapStyle: {
    height: Dimensions.get('window').height / 3,
    marginBottom: 10,
    marginRight: 5,
    marginLeft: 5,
  },
});