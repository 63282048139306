import React, { useState, useLayoutEffect } from 'react';
import { View, Text, Dimensions } from 'react-native';
import Gallery from 'react-native-image-gallery';
import { Button, Icon } from 'react-native-elements';
import * as Sharing from 'expo-sharing'; 
import * as FileSystem from 'expo-file-system';
import Loading from './../../components/Loading';
import { responsiveFontSize, responsiveHeight } from 'react-native-responsive-dimensions';



export default function GallerySwipper({ route, navigation }) {
	
	const { index: indexInit, images: imagesTemp } = route.params;
	const images = imagesTemp.filter( image => image.empty ? false : true );

	const downloadPath = `${FileSystem.cacheDirectory}${"imgTempToShare.jpg"}`;

	const [loading, setLoading ] = useState(false);
	const [index, setIndex] = useState(indexInit);

	useLayoutEffect(() => {
		navigation.setOptions({
			headerRight: () => <Icon size={responsiveFontSize(2.5)} containerStyle={ {marginRight: 10} } onPress={shareCurrentImageByUrl} name='share' type='materialicons' color='white'/>
		});
	}, [navigation]);

	const shareCurrentImageByUrl = async () => {
		setLoading(true);
		const url = images[index].source.uri;
        const { uri: localUrl } = await FileSystem.downloadAsync(
          url,
          downloadPath
        );

        if (!(await Sharing.isAvailableAsync())) {
        	setLoading(false);
          	alert('Función no compatible para su dispositivo');
         	return;
        }
        
        setLoading(false);
        await Sharing.shareAsync(localUrl);
	}

	const onChangeImage = (index) => {
		setIndex(index);
	}

	return (
		<View style={{flex:1}}>
			<Loading isVisible={loading} />
			<Gallery
			  style={{ flex: 1, backgroundColor: 'black' }}
			  images={images}
			  flatListProps={{
			    windowSize: 3, // limits memory usage to 3 screens full of photos (ie. 3 photos)
			    initialNumToRender: 3, // limit amount, must also be limited, is not controlled by other props
			    maxToRenderPerBatch: 2, // when rendering ahead, how many should we render at the same time
			    getItemLayout: (data, index) => ({ // fixes scroll and pinch behavior
			      length: Dimensions.get('screen').width,
			      offset: Dimensions.get('screen').width * index,
			      index,
			    }),
			  }}
			  removeClippedSubviews
			  initialPage={index}
			  onPageSelected={onChangeImage}
			/>

	      	<View style={{ bottom: 0, height: responsiveHeight(30), width: '100%', position: 'absolute', justifyContent: 'center' }}>
	      		<Text style={ {fontSize: responsiveFontSize(2.5), fontStyle: 'italic', color: 'white', textAlign: 'center'} }> { images[index].description }</Text>
            </View>
      	</View>
	)
}