import React from 'react';
import { View, Text, StyleSheet, ImageBackground, FlatList, TouchableOpacity, SafeAreaView, Image } from 'react-native';
import { responsiveHeight, responsiveWidth, responsiveFontSize } from 'react-native-responsive-dimensions';

export default function Menu({ moduleId, onPressMenuItem }) {

	const module = global.modules[moduleId];
	const children = module.children;
    const preview = { uri: "" };

	const keyExtractor = (item) => item.id;
	 
	// Componente que se renderiza por cada item del flatlist
	const renderItem = ({ item }) => {
		return (
	    	<TouchableOpacity onPress={() => onPressMenuItem(item.id, item.moduleTypeId, item.phone, item.url, item.title, item.email, item.text) } style={styles.touchableOpacity}>
   				<Image resizeMode="contain" style={ styles.image } source={{uri: item.iconUrl}}  /> 
			    { module.showTitles && <Text style={styles.title}>{item.title}</Text> }
	  		</TouchableOpacity>
  		)
	};

	return (
		<View style={styles.container} >
			<ImageBackground resizeMode="cover" source={ require('./../../../assets/fondo.jpg') } style={styles.imageBackground} >
					<View style={styles.view}>
					    <SafeAreaView style={styles.safeAreaView}>
						      <FlatList horizontal={true} 
						        data={children}
						        renderItem={renderItem}
						        keyExtractor={keyExtractor}
						        showsHorizontalScrollIndicator={false}
						        removeClippedSubviews={true} 
						        style={styles.flatList}
						        />
					    </SafeAreaView>
					</View>
			</ImageBackground>
		</View>	
	)
};

const styles = StyleSheet.create({
	container: {
		flex: 1
	},
	imageBackground: {
		flex: 1
	},
	view: {
		flex: 1,
		justifyContent: 'flex-end',
	},
	safeAreaView: {
	    height: 'auto',
	    marginBottom: responsiveHeight(13),
  	},
  	flatList: {
		flexGrow: 0,
  	},
	touchableOpacity: {
		height: 'auto',
	    flex: 1, 
	    justifyContent: 'center',
	    alignItems: 'center', 
	    //padding: 8,
	    paddingTop: 12,
	    paddingBottom: 12,
	    backgroundColor: '#F2F2F2', 
	    width: responsiveWidth(36.5),
	},
	image: { 
		height: responsiveHeight(9), 
		width: responsiveWidth(23),
		padding: 1
	},
	title: {
		marginTop: 3,
		fontSize:responsiveFontSize(1.8),
		textAlign: 'center',
		//fontWeight: 'bold'
	}
})

