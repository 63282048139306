import React, { useState, useEffect } from 'react';
import { Dimensions, StyleSheet, FlatList, View, Text, Image, TouchableOpacity  } from 'react-native';
import db from './../../utils/firestore';

const WIDTH = Dimensions.get('window').width;

export default function PhotoGallery({ route, navigation }) {
    const { moduleId } = route.params;
	const module = global.modules[moduleId];
    const numColumns = 4;

    const [ images, setImages ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    useEffect( ()=> {
        db.collection('modules').doc(moduleId).collection('images').orderBy('index')
        .get()
        .then( snapshot => {
            let finalImages = []; 
            snapshot.forEach( (image, index) => {
                finalImages.push( {id: index, source: { uri: image.get('url') }, description: image.get('description') } )
            } );
            setLoading(false);
            console.log(finalImages)
            setImages(finalImages);
        })

    }, [])
    
    

    const formatData = (dataList, numColumns) => {
        const totalRows = Math.floor(dataList.length / numColumns);
        let totalLastRow = dataList.length - (totalRows * numColumns);

        while (totalLastRow !== 0 && totalLastRow !== numColumns) {
            dataList.push({ id: totalLastRow, empty: true});
            totalLastRow++;
        }
 
        return dataList;
    }

    const renderItem = ({item, index}) => {
        const { itemStyle, itemText, itemInvisible } = styles;

        if (item.empty) {
            return (
                <TouchableOpacity style={[itemStyle, itemInvisible]} />
            )
        }

        return (
            <TouchableOpacity style={itemStyle} onPress={ () => navigation.navigate('gallerySwipper', {images, index, title: module.title} ) }>
                 <Image source={ {uri: item.source.uri} } style={styles.image} resizeMode="cover"/>
            </TouchableOpacity>
        )
    }

    return (
        <View styles={styles.container}>
            { loading && <Text>Cargando imágenes...</Text> }
            { (images.length == 0 && !loading) && <Text> No hay imágenes para mostrar </Text> }
            <FlatList 
                data={formatData(images, numColumns)}
                renderItem={renderItem}
                keyExtractor={(item, index) => index.toString()}
                numColumns={numColumns}
                initialNumToRender={6}
                maxToRenderPerBatch={6}
                removeClippedSubviews={true}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    image: {
        width: "100%", 
        height: "100%",
        borderWidth: 1,
        borderColor: '#a39e9d',
        borderRadius: 2,
    },
    itemStyle: {
        alignItems: 'center',
        justifyContent: 'center',
        height: 100,
        flex: 1,
        margin: 1,
        height: WIDTH / 4
    },
    itemText: {
        color: 'white',
        fontSize: 30,
    },
    itemInvisible: {
        backgroundColor: 'transparent'
    }
})