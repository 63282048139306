import * as firebase from 'firebase';
import "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyAGzAOjh4s9uTnfdI00qfOeMhyxgd0Xrps",
    authDomain: "servicios-al-contribuyen-2ada7.firebaseapp.com",
    databaseURL: "https://servicios-al-contribuyen-2ada7.firebaseio.com",
    projectId: "servicios-al-contribuyen-2ada7",
    storageBucket: "servicios-al-contribuyen-2ada7.appspot.com",
    messagingSenderId: "404050322304",
    appId: "1:404050322304:web:8e194e3038d094cbbf1765",
    measurementId: "G-KT9NDDQLSR"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;

export default messaging;
