import React from 'react';
import ListMenu from './ListMenu';
import HorizontalMenu from './HorizontalMenu';
import * as Linking from 'expo-linking';


export default function Menu({ route, navigation }) {
    const moduleId = route.params ? route.params.moduleId : 'UFGRud5IYEqFam5ZHuHu';
    const module = global.modules[moduleId];
    
    const onPressMenuItem = (childId, childModuleTypeId, phone, url, title, email, text) => {
        const params = { moduleId: childId, title };

        switch (childModuleTypeId) {
            case 'R4o1dieXvERZk5hLl1Fb':
                // video de youtube
                Linking.openURL(url);
                break;
            case 'HWisJi8hI3dT8DhtVWF0':
                // abrir web
                Linking.openURL(url);
                break;
            case 'Hm1rabnQF7MfDVi7Wl43':
                // enviar correo
                const body = text ? text : '';
                Linking.openURL(`mailto:${email}?body=${body}`);
                break;
            case 'MtmdCjKfTtM9KLDw0ywT':
                // videos de youtube
                navigation.navigate('youtubeVideoList', params);
                break;
            case 'W1Xiv00Ksx9GKsjUm5ms':
                // contenido a la medida
                navigation.navigate('customContent', params );
                break;
            case 'ZitKfpynE6JGYjcTZIRG':
                // llamar por telefono
                Linking.openURL(`tel:${phone}`);
                break;
            case 'fiUfY4BjpRxUG56SvefT':
                // calendario de eventos
                navigation.navigate('events', params );
                break;
            case 'lbHAQyy7DxZrpWfKNmY5':
                // menú
                navigation.push('menu', params );
                break;
            case 'liv5BT5qCqojDUZsppZh':
                navigation.navigate('contactForm', params );
                break;
            case 'nd5cqa3Xz65JH3LpbbAh':
                // galeria de fotos
                navigation.navigate('photoGallery', params );
                break;
            case 'xs1fYHc2mEvX10Fq3TG8':
                // web embebida
                const isSocialNetwork = url.includes('https://www.facebook.com/', 0) || url.includes('https://twitter.com/', 0);
                isSocialNetwork ? Linking.openURL(url) : navigation.navigate('webEmbeded', params );
                break;
            case 'KKkK9PfTnjCOioJ5We8j':
                // encuesta
                navigation.navigate('poll', params );
                break;
            case 'uGZTu89E5rqQ2wPYtheN':
                // mapa con varios puntos
                navigation.navigate('manyLocationsMap', params );
                break;
            case 'Yk9nYiiY2Zll0UZIkDo4':
                // mapa de un punto
                navigation.navigate('singleMarketMap', params);
                break;
        }
    }

    return module.menuTypeId == "QmnZtH5qKDxKckEWkyxV" ? <HorizontalMenu moduleId={moduleId} onPressMenuItem={onPressMenuItem} /> : <ListMenu moduleId={moduleId} onPressMenuItem={onPressMenuItem} />;

}