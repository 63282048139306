import React from 'react';
import { Text, View, StyleSheet, ActivityIndicator } from 'react-native';
import { Overlay } from 'react-native-elements';


export default function Loading(props) {
	
	const { isVisible, text } = props;

	return (
		<Overlay
			isVisible={isVisible}
			windowBackgorundColor="rgba(0,0,0,0.5)"
			overlayBackgroundColor="transparent"
			overlayStyle={styles.overlay}
		>
			<View style={ styles.view } >
				<ActivityIndicator size="large" color="#0055b8"/>
				{ text && <Text style={ styles.text } > {text} </Text> }
			</View>
		</Overlay>
	)
}

const styles = StyleSheet.create({
	overlay: {
		height: 80,
		width: 80,
		backgroundColor: "#fff",
		borderColor: "white",
		borderWidth: 2,
		borderRadius: 10,
	},
	view: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center"
	},
	text: {
		color: "#0055b8",
		textTransform: "uppercase",
		marginTop: 10
	}
})