/* eslint-env browser */

if ('serviceWorker' in navigator) {
  window.addEventListener('load', async () => {
    try {
      await navigator.serviceWorker.register('/expo-service-worker.js', { scope: '/' })
     // await navigator.serviceWorker.register('SW_PUBLIC_URL/firebase-messaging-sw.js', { scope: 'SW_PUBLIC_SCOPE' })
    } catch (e) {
      console.log("Error");
      console.log(e);
    }

  });
}
