import React, { useState, useEffect } from 'react';
import { SafeAreaView, FlatList, Text, Image } from 'react-native';
import { ListItem } from 'react-native-elements';
import * as Linking from 'expo-linking';


export default function YoutubeVideoList({route, navigation}) {
	const { moduleId } = route.params;
	const module = global.modules[moduleId];
	const API_KEY = "AIzaSyAJJxhwmemjGVIrR0kDe6NDyhBrfHpNDEo";
	const [ infoListVideos, setInfoListVideos ] = useState({
		videos: [],
		nextPageToken: '',
		playlistId: '',
		loading: true,
	});
	
	const getStartVideos = async () => {
		
		const youtubeUrl = module.url;
		let playlistId = '';
		let videos = [];

		// Obtiene el playlistId desde la url
		if (youtubeUrl.includes('/user/')) {
			const startIndex = youtubeUrl.indexOf('/user/') + 6;
			const user = youtubeUrl.substr(startIndex, youtubeUrl.length);
			const res = await fetch(`https://www.googleapis.com/youtube/v3/channels?part=contentDetails&forUsername=${user}&key=${API_KEY}`)
			const json = await res.json()
			
			playlistId = json.items[0].contentDetails.relatedPlaylists.uploads;

		} else {
			const startIndex = youtubeUrl.indexOf('&list=') + 6;
			playlistId = youtubeUrl.substr(startIndex, youtubeUrl.length);
		}
		
		let url = `https://www.googleapis.com/youtube/v3/playlistItems?playlistId=${playlistId}&part=snippet%2CcontentDetails&maxResults=5&key=${API_KEY}`;
		let res = await fetch(url);
		let json = await res.json();
		const nextPageToken = json.nextPageToken != undefined ? json.nextPageToken : null;
		setInfoListVideos({
			videos: json.items,
			nextPageToken : nextPageToken,
			playlistId,
			loading: false,
		});
	}
	
	useEffect( () => {
		getStartVideos();
	}, [] );

	const _handleLoadMore = async () => {
		 
		setInfoListVideos({
			...infoListVideos,
			loading: true,
		})
		
		if (infoListVideos.nextPageToken == null) {
			setInfoListVideos({
				...infoListVideos,
				loading: false,
				nextPageToken: null
			})
		};

		const url = `https://www.googleapis.com/youtube/v3/playlistItems?playlistId=${infoListVideos.playlistId}&part=snippet%2CcontentDetails&maxResults=5&key=${API_KEY}&pageToken=${infoListVideos.nextPageToken}`;
		const res = await fetch(url);
		const json = await res.json();

		setInfoListVideos({
			...infoListVideos,
			videos: infoListVideos.videos.concat(json.items),
			nextPageToken: json.nextPageToken != undefined ? json.nextPageToken : null,
			loading: false
		})
	};

	const keyExtractor = (_, index) => index.toString();
	const renderItem = ({item}) => <ListItem
										key={item.contentDetails.videoId} 
										title={item.snippet.title}
										bottomDivider
										onPress={ () => Linking.openURL(`https://www.youtube.com/watch?v=${item.contentDetails.videoId}`) }
										leftElement={
											<Image source={{uri: item.snippet.thumbnails.medium.url}} style={{height: 100, width: 150}} resizeMode="contain"/>
										}
                					/>				

	return (
		<SafeAreaView style={{flex:1}}>
			<FlatList 
				data={infoListVideos.videos}
				keyExtractor={keyExtractor}
				renderItem={renderItem}
				onEndReached={_handleLoadMore}
				onEndReachedThreshold={3}
			/>
			{ infoListVideos.loading && <Text> Cargando más videos...</Text> }
		</SafeAreaView>
	)
}