import React, { useLayoutEffect } from 'react';
import { ScrollView, StyleSheet, Text, Dimensions, Image, TouchableOpacity, Share } from 'react-native';
import { responsiveFontSize } from 'react-native-responsive-dimensions';
import { Icon, Button } from 'react-native-elements';
import HeroImage from './../../components/HeroImage';
import ListMenu from './../menu/ListMenu';
import * as Linking from 'expo-linking';


export default function SingleMarketMap({ navigation, route }) {
  const { moduleId } = route.params;
	const module = global.modules[moduleId];
  const { latitude, longitude, address, title, description, imageTopUrl=undefined } = module;
  const apiKey = "AIzaSyDSLgUSBKRyFoFJ7yxFxxQyIoRh7TOPjiw";
  const zoom = 19;
  const urlStaticMapImage = `https://maps.googleapis.com/maps/api/staticmap?zoom=${zoom}&size=620x400&maptype=roadmap&markers=color:red%7C${latitude},${longitude}&key=${apiKey}`;
  const urlGoogleMapsToShare = `https://maps.google.com/?q=${latitude}%2C${longitude}`;

  useLayoutEffect(()=>{
    navigation.setOptions({
      headerRight: () => <Icon size={responsiveFontSize(2.5)} containerStyle={ {marginRight: 10} } onPress={shareLocation} name='share' type='materialicons' color='white'/>
    })
  }, [navigation]);

  const linkingGoogleMapsApp = () => {
    Linking.openURL(`https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}&dir_action=navigate`);
  };

  const onPressMenuItem = (childId, childModuleTypeId, phone, url, title, email, text) => {
        const params = { moduleId: childId, title };

        switch (childModuleTypeId) {
            case 'R4o1dieXvERZk5hLl1Fb':
                // video de youtube
                Linking.openURL(url);
                break;
            case 'HWisJi8hI3dT8DhtVWF0':
                // abrir web
                Linking.openURL(url);
                break;
            case 'Hm1rabnQF7MfDVi7Wl43':
                // enviar correo
                const body = text ? text : '';
                Linking.openURL(`mailto:${email}?body=${body}`);
                break;
            case 'MtmdCjKfTtM9KLDw0ywT':
                // videos de youtube
                navigation.navigate('youtubeVideoList', params);
                break;
            case 'W1Xiv00Ksx9GKsjUm5ms':
                // contenido a la medida
                navigation.navigate('customContent', params );
                break;
            case 'ZitKfpynE6JGYjcTZIRG':
                // llamar por telefono
                Linking.openURL(`tel:${phone}`);
                break;
            case 'fiUfY4BjpRxUG56SvefT':
                // calendario de eventos
                navigation.navigate('events', params );
                break;
            case 'lbHAQyy7DxZrpWfKNmY5':
                // menú
                navigation.push('menu', params );
                break;
            case 'liv5BT5qCqojDUZsppZh':
                navigation.navigate('contactForm', params );
                break;
            case 'nd5cqa3Xz65JH3LpbbAh':
                // galeria de fotos
                navigation.navigate('photoGallery', params );
                break;
            case 'xs1fYHc2mEvX10Fq3TG8':
                // web embebida
                navigation.navigate('webEmbeded', params );
                break;
            case 'KKkK9PfTnjCOioJ5We8j':
                // encuesta
                navigation.navigate('poll', params );
                break;
            case 'uGZTu89E5rqQ2wPYtheN':
                // mapa con varios puntos
                navigation.navigate('manyLocationsMap', params );
                break;
            case 'Yk9nYiiY2Zll0UZIkDo4':
                // mapa de un punto
                navigation.navigate('singleMarketMap', params);
                break;
        }
    }

  const shareLocation = async () => {
    const message = `${address} (${urlGoogleMapsToShare})`;
    await Share.share({message});
  }

	return (
    <ScrollView style={styles.container}>
      { module.imageTopUrl != undefined && <HeroImage imageTopUrl={imageTopUrl} height={10} /> }
      
      <Text style={styles.address}>{address}</Text>
    
      <TouchableOpacity onPress={ () => navigation.navigate('fullScreenMap', { latitude, longitude, title, description, address } ) } >
        <Image style={styles.mapStyle} source={ {uri: urlStaticMapImage} } />
      </TouchableOpacity>
      
      <Button title='Llévame ahí' 
        buttonStyle={ {backgroundColor: '#0055b8', marginLeft: 5, marginRight: 5 } }
        titleStyle={ {color: 'white'} }
        onPress={ linkingGoogleMapsApp }
        />

      { module.children && 
        <ListMenu moduleId={moduleId} onPressMenuItem={onPressMenuItem}/> 
      }
      
      </ScrollView>
    );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  directionContainer: {
    flex: 0.3,
    flexDirection: 'row',
    padding: 10
  },
  address: {
    fontSize: responsiveFontSize(1.8),
    padding: 8,
  },
  mapStyle: {
    height: Dimensions.get('window').height / 3,
    marginBottom: 10,
    marginRight: 5,
    marginLeft: 5,
  },
});