import React from 'react';
import { View, FlatList, Image } from 'react-native';
import { ListItem } from 'react-native-elements'
import HeroImage from './../../components/HeroImage';


export default function ListMenu({ moduleId, onPressMenuItem }) {
	const module = global.modules[moduleId];
	const keyExtractor = (item) => item.id;

	const renderItem = ({item}) => 
							<ListItem 
								key={item.id} 
								containerStyle={{padding: 6}} 
								onPress={ () => onPressMenuItem(item.id, item.moduleTypeId, item.phone, item.url, item.title, item.email, item.text)  }
								bottomDivider 
						     >
								<Image
									style={ {height: 60, width: 60} }
									source={{uri: item.iconUrl}}
								/>
								<ListItem.Content>
									<ListItem.Title>{item.title}</ListItem.Title>
								</ListItem.Content>
								<ListItem.Chevron />
							</ListItem>

    return (
        <View style={{flex: 1}}>
    		{ (module.imageTopUrl != undefined && module.imageTopUrl !=  "" && module.moduleTypeId == "lbHAQyy7DxZrpWfKNmY5") && <HeroImage imageTopUrl={module.imageTopUrl} height={20}/> }
			<FlatList 
			    data={module.children} 
			    removeClippedSubviews={true}
			    initialNumToRender={10}
			    maxToRenderPerBatch={10}
			    keyExtractor={keyExtractor}
			    renderItem={renderItem}
			    windowSize={20}	
			/>				
        </View>
    )
}