import React, { useState } from 'react';
import { ScrollView, Alert } from 'react-native';
import { Card, Button, Input } from 'react-native-elements';

function showAlert(title, message) {
	Alert.alert(
      title,
      message,
      [
        { text: "OK", onPress: () => null }
      ],
      { cancelable: false }
    );
}

export default function ContactForm({ route }) {
  const { moduleId } = route.params;
	const module = global.modules[moduleId];
	const { email, sendCopy } = module;
	const [datos, setDatos] = useState({name: '', email: '', phone: '', message: ''});
  const [loading, setLoading] = useState(false);

	const handleInputChange = (inputName, value) => {
      setDatos({
        ...datos,
        [inputName] : value
      })
    }

  const sendEmail = () => { 
    //setLoading(true);

    if (datos.name == '' || datos.phone == '' || datos.email == '' || datos.message == ''){
      setLoading(false);
      showAlert('Rellene todos los campos', 'Debe de llenar todos los campos');
      return;
    }
	
	showAlert('Enviando correo', 'Espere un momento por favor... ');

    const text = `Nombre: ${datos.name} \nEmail: ${datos.email} \nTeléfono: ${datos.phone} \nMensaje: ${datos.message}`
    
    const emailToSend = {
      to: email,
      subject: 'Formulario de contacto - Cullinans Joyería App',
      text,
      sendCopy,
    }

    fetch('https://us-central1-absa-go-fed68.cloudfunctions.net/service/mailing', {
      method: 'POST',
       headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(emailToSend)
    })
    .then(res => res.json())
    .then(data => { 
      setLoading(false);
      console.log(data)
      if (data.success)
        showAlert('Correo enviado', 'El correo se envió exitósamente');  
      else
        showAlert('Upps, ocurrió un error', 'Intente de nuevo por favor');
    })
    }

	return (
		<ScrollView style={ {backgroundColor: '#F5F5F5'} }>
			<Card containerStyle={{height: '100%'}}>
			  <Input onChangeText={ value => handleInputChange('name', value) } name="name" label="Nombre" placeholder='nombre completo' rightIcon={{ type: 'font-awesome', name: 'user' }}/>
			  <Input onChangeText={value => handleInputChange('email', value)} name="email" label="Correo" placeholder='correo electrónico' rightIcon={{ type: 'font-awesome', name: 'envelope' }}/>
			  <Input onChangeText={value => handleInputChange('phone', value)} name="phone" label="Teléfono" placeholder='teléfono'rightIcon={{ type: 'font-awesome', name: 'phone' }}/>
			  <Input onChangeText={value => handleInputChange('message', value)} name="message" label="Mensaje" placeholder='escriba su mensaje' multiline={true} numberOfLines={4} rightIcon={{ type: 'font-awesome', name: 'font' }}/>
			  <Button loading={loading} onPress={sendEmail} buttonStyle={{ backgroundColor: '#a39e9d', borderRadius: 0,
        marginLeft: 0, marginRight: 0, marginBottom: 0}} titleStyle={ {color: 'white'} } title='Enviar' />
			</Card>
		</ScrollView>
	)
}